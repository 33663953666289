<template>
  <div class="user-page">
    <component :is="currentComponent">
      <template #operation>
        <TabBar :tabs="tabs" v-model="tabIndex" />
      </template>
    </component>
  </div>
</template>

<script>
import UserListPage from './UserListPage.vue';
import SiteListPage from './SiteListPage.vue';
import TabBar from '@/components/TabBar.vue';

export default {
  components: {
    TabBar,
    UserListPage,
    SiteListPage
  },
  data() {
    return {
      tabIndex: 0,
      tabs: ['User', 'Site']
    };
  },
  computed: {
    currentComponent() {
      return this.tabIndex === 0 ? 'UserListPage' : 'SiteListPage';
    }
  }
};
</script>

<style>
.user-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: sans-serif;
}
</style>