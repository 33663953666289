<template>
  <div class="tab-bar" ref="tabBarRef">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      :class="['tab', { active: activeTab === index }]"
      @click="selectTab(index)">
      <span>{{ tab }}</span>
    </div>
    <div class="indicator" :style="indicatorStyle"></div>
  </div>
</template>

<script>
export default {
  emits: ['input'],
  props: {
    tabs: Array,
    value: Number,
  },
  data() {
    return {
      activeTab: this.value,
    };
  },
  computed: {
    indicatorStyle() {
      const tabWidth = 100 / this.tabs.length; // Width of each tab as a percentage
      const paddingLeft = 10; // Left padding in pixels
      return {
        left: `calc(${tabWidth * this.activeTab}% + ${paddingLeft}px)`,
        width: `calc(${tabWidth}% - ${paddingLeft * 2}px)`, // Adjust width to account for padding
      };
    },
  },
  methods: {
    selectTab(index) {
      this.activeTab = index;
      this.$emit('input', index);
    },
  },
};
</script>

<style scoped>
.tab-bar {
  display: flex;
  position: relative;
}

.tab {
  flex: 1;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.85);
}

.tab.active {
  font-weight: bold;
}

.indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  background-color: #409EFF;
}
</style>