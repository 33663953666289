<template>
  <div class="site-list-page">
    <div class="operation-panel">
      <div class="logo"><img src="@/assets/7-eleven.png" />7-Eleven</div>
      <div class="control-bar">
        <el-button class="cereb-button" @click="handleAdd" icon="el-icon-plus">
          Add Site
        </el-button>
      </div>
    </div>
    <div class="operation-site-panel">
      <slot name="operation"></slot>
    </div>
    <div class="content">
      <div class="filter">
        <span></span>
        <div class="right-region">
          <el-input prefix-icon="el-icon-search" v-model="keywords" placeholder="Site Name" clearable class="cereb-input list-view-search-input" />
        </div>
      </div>
      <el-table height="auto" :empty-text="'No Data'" :data="computedTable" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.3)" class="cereb-table full-width">
        <el-table-column prop="id" label="ID" width="220" :align="'center'" />
        <el-table-column prop="name" label="Site name" width="160" />
        <el-table-column prop="address" label="Address" />
        <el-table-column prop="creationTime" label="Creation Time" :align="'center'">
          <template slot-scope="scope">
            <span>{{ scope.row.creationTime | formatTimestamp }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Operations" width="200">
          <template slot-scope="scope">
            <div class="operation-wrap">
              <el-button class="operation-button" @click="handleEdit(scope.row)" type="text" size="small">Edit</el-button>
              <el-button class="operation-button" @click="handleDelete(scope.row)" type="text" size="small">Delete</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrap">
      <span><b>{{ totalItems }}</b>&nbsp;sites</span>
      <el-pagination class="cereb-pagination" background layout="prev, pager, next" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" :total="totalItems" />
    </div>
    <DialogSiteEdit :visible.sync="showSiteDialog" :value="editFormData" @input="handleAppendItem" />
  </div>
</template>

<script>
import { MessageBox } from 'element-ui';
import DialogSiteEdit from './components/DialogSiteEdit.vue';
import { mapGetters, mapActions } from 'vuex';

class SiteModel {
  constructor({ id, siteName, address, creationTime } = {}) {
    this.id = id;
    this.siteName = siteName;
    this.address = address;
    this.creationTime = creationTime;
  }
}
export default {
  components: {
    DialogSiteEdit
  },
  data: function () {
    return {
      keywords: "",
      showSiteDialog: false,
      editFormData: {},
      currentPage: 1,
      pageSize: 10,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      sites: 'siteStore/sites'
    }),
    siteSelectOptions() {
      return [{ label: "All", value: null },
      ...this.sites.map(site => {
        return {
          value: site.id,
          label: site.name
        }
      })]
    },
    totalItems() {
      return this.computedTable.length
    },
    computedTable() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      const filteredSites = this.sites.filter(site => {
        return site.name.toLowerCase().includes(this.keywords.toLowerCase()) ||
          site.address.toLowerCase().includes(this.keywords.toLowerCase())
      });
      return filteredSites.slice(start, end);
    },
  },
  watch: {
    keywords: {
      handler() {
        this.currentPage = 1
      },
      deep: true
    },
  },
  methods: {
    ...mapActions({
      fetchSites: 'siteStore/fetchSites',
      deleteSite: 'siteStore/deleteSite'
    }),
    getStatusText(text) {
      if (text.substring(0, 7) === 'expired') {
        return text.substring(0, 7)
      } else {
        return text
      }
    },
    getStatusButtonText(text) {
      if (text.substring(0, 7) === 'expired') {
        return text.substring(8) === 'active' ? 'Deactivate' : 'Activate'
      } else {
        return text === 'active' ? 'Deactivate' : 'Activate'
      }
    },
    defaultErrorHandling(err) {
      console.log(err)
      if (err === 'invalid auth') {
        this.$message.error({ center: true, message: 'invalid salto authentication' });
      } else if (err === 'unauthorized') {
        this.$message.error({ center: true, message: 'login expired' });
        this.logout()
      } else {
        this.$message.error({ center: true, message: 'network error' });
      }
    },
    handleCurrentChange(page) {
      this.currentPage = page;
    },
    async fetchData() {
      this.loading = true
      try {
        await this.fetchSites()
      } catch (err) {
        this.defaultErrorHandling(err);
      } finally {
        this.loading = false;
      }
    },
    async updateStatus(user) {
      user.status = user.status === 'active' ? 'inactive' : 'active';
    },
    handleAppendItem(data) {
      const index = this.sites.findIndex(user => user.id === data.id);
      if (index !== -1) {
        this.sites.splice(index, 1, data);
      } else {
        this.sites.push(data);
      }
    },
    handleAdd() {
      this.editFormData = new SiteModel()
      this.showSiteDialog = true;
    },
    handleEdit(row) {
      var index = (this.sites || []).findIndex((x) => x.id === row.id);
      var editItem = this.sites[index]
      Object.assign(this.editFormData, editItem)
      this.showSiteDialog = true;
    },
    async handleDelete(row) {
      try {
        await MessageBox.confirm('<div style="font-size: 15px; margin-left:12px; margin-bottom:40px;">Delete?</div>', '', {
          customClass: "msgBox",
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true
        });
        await this.deleteSite(row.id)
        this.$message.success({ center: true, message: 'success' });
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error({ center: true, message: 'fail' });
        }
        console.log('delete cancel.');
      }
    },
    formatTime(preTime) {
      if (preTime === null) {
        return 'null'
      }
      var t = new Date(preTime)
      var time = t.getFullYear() + '-' + (t.getMonth() + 1 < 10 ? "0" + (t.getMonth() + 1) : t.getMonth() +
        1) + '-' + (t.getDate() < 10 ? "0" + t.getDate() : t.getDate()) + ' ' + (t.getHours() < 10 ?
          "0" + t
            .getHours() : t.getHours()) + ':' + (t.getMinutes() < 10 ? "0" + t.getMinutes() : t
              .getMinutes()) + ':' + (t.getSeconds() < 10 ? "0" + t.getSeconds() : t.getSeconds());
      return time
    },
  },
  mounted: function () {
    this.fetchData()
  }
};
</script>

<style>
.site-list-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 25px 117px;
}

.site-list-page .operation-panel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 28px;
  color: rgba(255, 255, 255, 0.85);
}

.site-list-page .operation-panel .logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.site-list-page .operation-site-panel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.site-list-page .operation-site-panel a {
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none;
}

.site-list-page .operation-site-panel a:hover {
  text-decoration: underline;
}

.site-list-page .control-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-list-page .content {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  background-color: #182130;
  margin: 10px 0;
  padding: 10px 0;
}


.site-list-page .content .filter {
  color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 0 20px;
}

.site-list-page .content .filter .right-region {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.site-list-page .content .filter .right-region .list-view-search-input {
  width: 360px;
}

.site-list-page .cereb-table {
  flex: 1 1 0;
}

.site-list-page .operation-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 10px;
}

.site-list-page .pagination-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.85);
}

.site-list-page .indicator-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.site-list-page .status-indicator-active {
  width: 6px;
  height: 6px;
  background: #52C41A;
  border-radius: 50%;
}

.site-list-page .status-indicator-inactive {
  width: 6px;
  height: 6px;
  background: #FF4141;
  border-radius: 50%;
}

.site-list-page .status-indicator-pending {
  width: 6px;
  height: 6px;
  background: #CCCCCC;
  border-radius: 50%;
}

.site-list-page .status-indicator-else {
  width: 6px;
  height: 6px;
  background: #ffc107;
  border-radius: 50%;
}
</style>
