<template>
  <el-dialog destroy-on-close class='dialog-site-edit cereb-dialog' title="Edit Site" :visible="visible" @update:visible="onClose" width="500px" @open="onDialogOpen" top="120px">
    <el-form class="cereb-form" :model="formData" :rules="rules" ref="editForm" label-position="left" label-width="140px">
      <el-form-item prop="name" label="Site Name:">
        <el-input class="full-width cereb-input" placeholder="Type a Site name" v-model="formData.name" />
      </el-form-item>
      <el-form-item prop="address" label="Address:">
        <el-input class="full-width cereb-input" placeholder="Type a address" v-model="formData.address" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="onClose">Cancel</el-button>
      <el-button type="primary" @click="submitForm" :loading='isLoading'>Confirm</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  props: ['visible', 'value', 'input'],
  data() {
    return {
      isLoading: false,
      formData: {
        name: '',
        address: '',
      },
      rules: {
        name: [{
          required: true,
          message: 'Site name is required',
          trigger: ['blur']
        }],
        address: [{
          required: false,
          message: 'Number of site is required',
          trigger: ['blur']
        }],
      },
      expirationTimeValue: '',
    };
  },
  methods: {
    ...mapActions('siteStore', ['addOrUpdateSite']),
    onDialogOpen() {
      Object.assign(this.formData, this.value);
    },
    async submitForm() {
      await this.$refs.editForm.validate();
      try {
        this.isLoading = true;
        await this.addOrUpdateSite(this.formData);
        this.$emit('input', this.formData);
        this.$message.success('success');
      }
      finally {
        this.isLoading = false;
      }
      this.onClose();
    },
    onClose() {
      this.$refs.editForm.clearValidate();
      this.$emit('update:visible', false);
    }
  },
};
</script>
<style scoped>
.dialog-site-edit {
  text-align: left;
}

.dialog-site-edit .el-dialog__header {
  padding-top: 30px;
}

.dialog-site-edit .el-dialog__body {
  padding-bottom: 0;
  padding-top: 17px;
  padding-left: 30px;
  padding-right: 30px;
}

.dialog-site-edit .el-dialog__footer {
  padding-bottom: 30px;
}
</style>