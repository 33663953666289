<template>
  <div class="user-list-page">
    <div class="operation-panel">
      <div class="logo">
        <img src="@/assets/7-eleven.png" />
        <span>{{ user.organizationName || 'No organization assigned' }}</span>
      </div>
      <div class="control-bar">
        <el-button class="cereb-button" @click="handleAdd" icon="el-icon-plus">
          Add User
        </el-button>
      </div>
    </div>
    <div class="operation-site-panel">
      <slot name="operation"></slot>
    </div>
    <div class="content">
      <div class="filter">
        <span></span>
        <div class="right-region">
          <label>Site:
            <el-select v-model="filter.siteId" placeholder="Select" class="cereb-select">
              <el-option
                v-for="item of sites"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </label>
          <el-input prefix-icon="el-icon-search" placeholder="Account Name" clearable class="cereb-input list-view-search-input" @input="delaySearch" :value="filter.keywords" />
        </div>
      </div>
      <el-table height="auto" :empty-text="'No Data'" :data="tableData.list" v-loading="loadingUsers" element-loading-background="rgba(0, 0, 0, 0.3)" class="cereb-table full-width">
        <el-table-column prop="id" label="ID" width="220" :align="'center'" />
        <el-table-column prop="username" label="Account name" width="160" />
        <el-table-column prop="alias" label="Alias" :align="'center'" />
        <el-table-column prop="email" label="Email" />
        <el-table-column prop="siteId" label="Site">
          <template slot-scope="scope">
            {{ getSiteNameById(scope.row.siteId) }}
          </template>
        </el-table-column>
        <el-table-column prop="creationTime" label="Creation Time" :align="'center'">
          <template slot-scope="scope">
            {{ formatTime(scope.row.creationTime) }}
          </template>
        </el-table-column>
        <el-table-column label="Operations" width="200">
          <template slot-scope="scope">
            <div class="operation-wrap">
              <el-button class="operation-button" @click="handleEdit(scope.row)" type="text" size="small">Edit</el-button>
              <el-button class="operation-button" @click="handleDelete(scope.row)" type="text" size="small">Delete</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination-wrap">
      <span><b>{{ tableData.totalCount.toLocaleString() }}</b>&nbsp;users</span>
      <el-pagination class="cereb-pagination" background layout="prev, pager, next" @current-change="handleCurrentChange" :current-page="tableData.currentPage" :page-size="tableData.pageSize" :total="tableData.totalCount" />
    </div>
    <DialogUserEdit :visible.sync="showUserDialog" :list="tableData.list" :value="editFormData" @confirm="handleAppendItem" />
    <DialogSiteEdit :visible.sync="showSiteDialog" />
  </div>
</template>

<script>
import { MessageBox } from 'element-ui';
import DialogUserEdit from './components/DialogUserEdit.vue';
import DialogSiteEdit from './components/DialogSiteEdit.vue';
import * as  api from '@/api';
import { mapActions } from "vuex";

class UserModel {
  constructor({ id = null, username = '', alias = '', email = '', status = 'inactive', plan = '', creationTime = '', expirationTime = '', site = '#1' } = {}) {
    this.id = id;
    this.username = username;
    this.alias = alias;
    this.email = email;
    this.status = status;
    this.plan = plan;
    this.creationTime = creationTime;
    this.expirationTime = expirationTime;
    this.site = site
  }
}
export default {
  components: {
    DialogUserEdit,
    DialogSiteEdit
  },
  data: function () {
    return {
      showUserDialog: false,
      showSiteDialog: false,
      editFormData: {},
      filter: {
        siteId: '',
        keywords: "",
      },
      tableData: {
        currentPage: 1,
        currPage: 1,
        list: [],
        pageSize: 10,
        totalCount: 0,
        totalPage: 0,
      },
      loadingUsers: false
    }
  },
  computed: {
    user() {
      return this.$store.getters['user'];
    },
    totalItems() {
      return this.tableData.totalCount
    },
    displayTableData() {
      let currentPageUsers = this.deepClone(this.tableData.list)
      return currentPageUsers
    },
    sites() {
      const store = this.$store.getters['siteStore/sites'];
      return [
        { label: 'All', value: '' },
        ...store.map(site => ({ label: site.name, value: site.id }))
      ];
    }
  },
  methods: {
    ...mapActions('siteStore', ['fetchSites', 'getSiteNameById']),
    delaySearch(keywords) {
      this.filter.keywords = keywords;
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.tableData.currentPage = 1;
        this.loadUsers();
      }, 500);
    },
    getSiteNameById(id) {
      const site = this.sites.find(site => site.value === id);
      return site ? site.label : 'unset';
    },
    getStatusText(text) {
      if (text.substring(0, 7) === 'expired') {
        return text.substring(0, 7)
      } else {
        return text
      }
    },
    getStatusButtonText(text) {
      if (text.substring(0, 7) === 'expired') {
        return text.substring(8) === 'active' ? 'Deactivate' : 'Activate'
      } else {
        return text === 'active' ? 'Deactivate' : 'Activate'
      }
    },
    defaultErrorHandling(err) {
      console.log(err)
      if (err === 'invalid auth') {
        this.$message.error({ center: true, message: 'invalid salto authentication' });
      } else if (err === 'unauthorized') {
        this.$message.error({ center: true, message: 'login expired' });
        this.logout()
      } else {
        this.$message.error({ center: true, message: 'network error' });
      }
    },
    handleCurrentChange(page) {
      this.currentPage = page
      this.loadUsers()
    },
    async loadUsers() {
      this.loadingUsers = true
      try {
        const result = await api.getUserAccounts({ offset: this.currentPage, limit: this.pageSize, siteId: this.filter.siteId, name: this.keywords });
        if (result.list == null) {
          result.list = []
        }
        this.tableData = result;
      } catch (err) {
        this.defaultErrorHandling(err);
      } finally {
        this.loadingUsers = false;
      }
    },
    async updateStatus(user) {
      user.status = user.status === 'active' ? 'inactive' : 'active';
    },
    handleAppendItem() {
      this.loadUsers()
    },
    handleAdd() {
      this.editFormData = new UserModel()
      this.showUserDialog = true;
    },
    handleEdit(row) {
      var index = (this.tableData.list || []).findIndex((x) => x.id === row.id);
      var editItem = this.tableData.list[index]
      Object.assign(this.editFormData, editItem)
      this.showUserDialog = true;
    },
    async handleDelete(row) {
      try {
        await MessageBox.confirm('<div style="font-size: 15px; margin-left:12px; margin-bottom:40px;">Delete?</div>', '', {
          customClass: "msgBox",
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          dangerouslyUseHTMLString: true
        });
        const index = this.tableData.list.findIndex(user => user.email === row.email);
        if (index !== -1) {
          this.tableData.list.splice(index, 1);
        }
        this.$message.success({ center: true, message: 'success' });
      } catch (error) {
        if (error !== 'cancel') {
          this.$message.error({ center: true, message: 'fail' });
        }
        console.log('delete cancel.');
      }
    },
    formatTime(preTime) {
      if (preTime === null) {
        return 'null'
      }
      var t = new Date(preTime)
      var time = t.getFullYear() + '-' + (t.getMonth() + 1 < 10 ? "0" + (t.getMonth() + 1) : t.getMonth() +
        1) + '-' + (t.getDate() < 10 ? "0" + t.getDate() : t.getDate()) + ' ' + (t.getHours() < 10 ?
          "0" + t
            .getHours() : t.getHours()) + ':' + (t.getMinutes() < 10 ? "0" + t.getMinutes() : t
              .getMinutes()) + ':' + (t.getSeconds() < 10 ? "0" + t.getSeconds() : t.getSeconds());
      return time
    },
    deepClone(obj) {
      var _obj = JSON.stringify(obj),
        objClone = JSON.parse(_obj);
      return objClone;
    },
  },
  watch: {
    "filter.siteId"() {
      this.loadUsers()
    },
  },
  mounted: function () {
    this.loadUsers()
    this.fetchSites()
  }
};
</script>

<style>
.user-list-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 25px 117px;
}

.user-list-page .operation-panel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 28px;
  color: rgba(255, 255, 255, 0.85);
}

.user-list-page .operation-panel .logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.user-list-page .operation-site-panel {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.user-list-page .operation-site-panel a {
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none;
}

.user-list-page .operation-site-panel a:hover {
  text-decoration: underline;
}

.user-list-page .control-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.user-list-page .content {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  background-color: #182130;
  margin: 10px 0;
  padding: 10px 0;
}

.user-list-page .content .filter {
  color: rgba(255, 255, 255, 0.85);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  padding: 0 20px;
}

.user-list-page .content .filter .right-region {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.user-list-page .content .filter .right-region .list-view-search-input {
  width: 360px;
}

.user-list-page .cereb-table {
  flex: 1 1 0;
}

.user-list-page .operation-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 0 10px;
}

.user-list-page .pagination-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.85);
}

.user-list-page .indicator-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.user-list-page .status-indicator-active {
  width: 6px;
  height: 6px;
  background: #52C41A;
  border-radius: 50%;
}

.user-list-page .status-indicator-inactive {
  width: 6px;
  height: 6px;
  background: #FF4141;
  border-radius: 50%;
}

.user-list-page .status-indicator-pending {
  width: 6px;
  height: 6px;
  background: #CCCCCC;
  border-radius: 50%;
}

.user-list-page .status-indicator-else {
  width: 6px;
  height: 6px;
  background: #ffc107;
  border-radius: 50%;
}
</style>
